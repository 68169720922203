import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const DefListItem = props => {
  const { theme, children, ...other } = props
  return (
    <Root
      theme={theme}
      {...other}
    >
      {children}
    </Root>
  )
}

const getColor = props => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else if (props.scale) {
    return props.theme.scale[props.scale]
  } else if (props.color) {
    return getThemeColor(props.color, props.theme)
  } else {
    return props.theme.text.default
  }
}

const Root = styled.div`
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${getColor};
  }
  @media ${mq.and(desktop, laptop)} {
    padding: 24px 32px;
    white-space: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
  }
  @media ${mq.and(tablet, mobile)} {
    display: inline-block;
    padding-top: 24px;
    padding-right: 24px;
  }
`

export default ThemeConsumer(DefListItem)
