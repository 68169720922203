import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-company.png'
import Hello from '@/components/modules/Company/Hello'
import Philosophy from '@/components/modules/Company/Philosophy'
import Outline from '@/components/modules/Company/Outline'
import History from '@/components/modules/Company/History'
import CSV from '@/components/modules/Company/CSV'
import Access from '@/components/modules/Company/Access'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const CompanyIndexPage = props => (
  <Root>
    <SEO location={props.location} title="企業情報" description="株式会社ウィザードについてのご案内。会社概要、沿革、地域活動、アクセスマップなど。"/>
    <Head>
      <PageTitle src={imgTitle}>企業情報</PageTitle>
    </Head>
    <Main>
      <Row><Hello /></Row>
      <Row><Philosophy /></Row>
      <Row><Outline /></Row>
      <Row><History /></Row>
      <Row><CSV /></Row>
      <Row><Access /></Row>
      <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
      <Row css={styles.Contact}><ContactLead /></Row>
      <Row css={styles.Recruit}><RecruitLead /></Row>
    </Main>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(CompanyIndexPage)
