import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const DefListItem = props => {
  const { theme, children, ...other } = props
  return (
    <Root
      theme={theme}
      {...other}
    >
      {children}
    </Root>
  )
}

const getColor = props => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else if (props.scale) {
    return props.theme.scale[props.scale]
  } else if (props.color) {
    return getThemeColor(props.color, props.theme)
  } else {
    return props.theme.text.default
  }
}

const Root = styled.li`
  border-top: 1px solid ${getColor};
  @media ${mq.and(desktop, laptop)} {
    display: flex;
  }
  @media ${mq.and(tablet, mobile)} {
    padding: 0 0 32px;
  }
`

export default ThemeConsumer(DefListItem)
