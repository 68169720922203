import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { rgba, mq } from '@/utils/helpers'
import { mobile } from '@/utils/media'
import Typography from '@/components/common/Typography'
import GhostButton from '@/components/common/GhostButton'

const RecruitLead = props => (
  <Root theme={props.theme}>
    <Subtitle variant="h4" component="h2" lang="en">RECRUIT</Subtitle>
    <Title variant="h2" component="h3">ウィザードで働く</Title>
    <Body variant="h4" component="p">自分の未来は自分の手で創る。<br />ウィザードはそれができる環境を提供したいと思っています。</Body>
    <Button to="/recruit/" component={Link}>採用ページへ</Button>
  </Root>
)

export default ThemeConsumer(RecruitLead)

const Root = styled(layouts.components.FullWidth)`
  padding: 72px 0;
  background: linear-gradient(105.18deg,
    ${props => props.theme.scale[900]} 0.48%,
    ${props => rgba(props.theme.scale[900], 0.7)} 100%),
    ${props => props.theme.primary.dark};
  text-align: center;
`

const Subtitle = styled(Typography)`
  text-align: center;
`

const Title = styled(Typography)`
  margin-top: 18px;
  text-align: center;
`

const Body = styled(Typography)`
  margin-top: 24px;
  text-align: center;
  @media ${mq.and(mobile)} {
    padding: 0 16px;
  }
`

const Button = styled(GhostButton)`
  margin-top: 40px;
  width: 180px;
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`
