import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import Card from './PhilosophyCard'

const CompanyPhilosophy = props => (
  <StaticQuery
    query={query}
    render={ data => {
      const values = data.values.edges.map(edge => ({
        ...edge.node
      }))
      return (
        <Root theme={props.theme} className={props.className}>
          <Section
            subtitle="PHILOSOPHY"
            title="ウィザードの理念"
            titleProps={{
              variant: 'h3'
            }}
            subTitleProps={{
              lang: 'en'
            }}
          >
            <Main>
              <List>
                <List_Parent>
                  {values.map((value, index) => (
                    <List_Child key={index}><Card css={styles.Card} {...value}/></List_Child>
                  ))}
                </List_Parent>
              </List>
            </Main>
          </Section>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(CompanyPhilosophy)

const Root = styled.div`
  position: relative;
`

const Main = styled.div``

const List = styled.div`
`

const List_Parent = styled.ul`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    margin: 0 -8px;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin: -8px auto 0;
  }
`

const List_Child = styled.li`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    padding: 0 8px;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 8px;
  }
`

const styles = {
  Card: css`
    height: 100%;
  `
}

export const query = graphql`
  query CompanyPhilosophyQuery {
    values: allCompanyPhilosophyListYaml {
      edges {
        node {
          title
          body
        }
      }
    }
  }
`
