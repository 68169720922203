import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import DefList from '@/components/common/DefList'
import DefListItem from '@/components/common/DefListItem'
import DefListHead from '@/components/common/DefListHead'
import DefListDesc from '@/components/common/DefListDesc'
import ParticleBackground from '@/components/common/ParticleBackground'
import Typography from '@/components/common/Typography'

const CompanyHistory = ({ theme, list, ...other }) => (
  <StaticQuery
    query={query}
    render={data => {
      const list = data.list.edges.map(el => el.node)
      return (
        <Root {...other}>
          <Section
            subtitle="HISTORY"
            title="沿革"
            titleProps={{
              variant: 'h3'
            }}
            subTitleProps={{
              lang: 'en'
            }}
          >
            <Main>
              <Background scale={400} />
              <List>
                {list.map((item, index) => (
                  <Item key={index}>
                    <Item_Head>
                      <Item_Title
                        variant="h4"
                        lang="en"
                        component="h4">
                        {item.key}
                      </Item_Title>
                    </Item_Head>
                    <Item_Desc>
                      <Item_Content variant="body2" component="div" dangerouslySetInnerHTML={{ __html: item.value }} theme={theme} />
                    </Item_Desc>
                  </Item>
                ))}
              </List>
            </Main>
          </Section>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
`
const Main = styled.div`
  position: relative;
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding: 80px;
  }
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 40px 16px;
  }
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const List = styled(DefList)``

const Item = styled(DefListItem)``

const Item_Head = styled(DefListHead)`
  width: 200px;
  @media ${mq.and(mobile)} {
    width: auto;
  }
`

const Item_Desc = styled(DefListDesc)``

const Item_Title = styled(Typography)``

const Item_Content = styled(Typography)`
  a {
    font-weight: bold;
    color: ${props => getThemeColor('primary.light', props.theme)};
    text-decoration: underline;
  }
`

export default ThemeConsumer(CompanyHistory)

export const query = graphql`
  query CompanyHistoryQuery {
    list: allCompanyHistoryYaml {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
