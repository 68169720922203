import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Section from '@/components/common/Section'
import Typography from '@/components/common/Typography'

const CompanyHello = ({ theme, heading, html, ...other }) => (
  <Root {...other}>
    <Section
      subtitle="HELLO WORLD"
      title="ごあいさつ"
      titleProps={{
        variant: 'h3'
      }}
      subTitleProps={{
        lang: 'en'
      }}
    >
      <Main theme={theme}>
        <Title variant="h2" component="h4">「社員とその家族の幸せ」が会社の存在価値</Title>
        <Body variant="body2">
          株式会社ウィザードは1998年に設立した、ソフトウェア開発業務、IT関連業務全般業務を遂行する企業です。<br />
          企業の競争力の源泉は、効率的な事業の運営と不要なコストの削減にあるといえます。当社は、お客様の業務最適化をシステム構築という側面からサポートするべく、優れた人材による最先端の技術の提供に努めております。<br />
          きめ細やかなコンサルティングサービスと、ユーザーオリエンテッドなシステム導入による、顧客満足度の向上を最大に目的とした総合的なソリューションを提供しています。システム導入計画の策定から構築・運用サポートに至るまでをトータルにご提供できることが、ウィザードの大きな強みです。
        </Body>
      </Main>
    </Section>
  </Root>
)

const Root = styled.div``

const Main = styled.div`
  margin-top: 32px;
`

const Title = styled(Typography)``

const Body = styled(Typography)`
  margin-top: 20px;
`

export default ThemeConsumer(CompanyHello)
